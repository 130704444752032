import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {
  NbCalendarRangeModule,
  NbDatepickerModule,
  NbLayoutModule,
  NbMenuModule,
  NbSidebarModule,
  NbThemeModule,
  NbToastrModule
} from '@nebular/theme';
import {NbEvaIconsModule} from '@nebular/eva-icons';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NB_AUTH_TOKEN_INTERCEPTOR_FILTER, NbAuthModule, NbAuthSimpleToken, NbPasswordAuthStrategy} from '@nebular/auth';
import {APP_BASE_HREF} from '@angular/common';
import {UserService} from './services/user.service';
import {AuthGuard} from './services/auth-guard.service';
import {Interceptor} from './common/config/http.interceptor';
import {AgmCoreModule} from '@agm/core';
import {NgxCoolDialogsModule} from 'ngx-cool-dialogs';
import {RequestDialogComponent} from './pages/map/merchant/request-dialog/request-dialog.component';
import {ViewItemsComponent} from './pages/order-management/orders/view-items/view-items.component';
import {EditDialogComponent} from './pages/order-management/orders/edit-dialog/edit-dialog.component';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import {LeafletDrawModule} from '@asymmetrik/ngx-leaflet-draw';
import {OwlDateTimeModule} from 'ng-pick-datetime';
import {DataService} from './services/data.service';
import {PagesModule} from './pages/pages.module';
import {SharedModule} from './common/shared.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {environment} from '../environments/environment';
import {NgxFreshChatModule} from 'ngx-freshchat';
import {MessagingService} from './services/messaging.service';
import {AngularFireModule} from '@angular/fire';
import {AngularFireDatabase} from '@angular/fire/database';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFireMessaging, AngularFireMessagingModule} from '@angular/fire/messaging';
import { ServiceWorkerModule } from '@angular/service-worker';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    SharedModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NbThemeModule.forRoot({name: 'corporate'}),
    NbLayoutModule,
    NbEvaIconsModule,
    NgbModule,
    NgxFreshChatModule,
    NgxCoolDialogsModule.forRoot(),
    LeafletModule.forRoot(),
    LeafletDrawModule.forRoot(),
    NbSidebarModule.forRoot(),
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireMessagingModule,
    AgmCoreModule.forRoot({
      // apiKey: 'AIzaSyD_1-vMJmN14WLQm-vKFS6-SKZ4-EGi8ic',
      apiKey: 'AIzaSyCxpEJRNxuy1KRNZ1XeUjzQdlf0GjCZwGg',
      language: 'en',
      libraries: ['geometry', 'places'],
    }),
    NbAuthModule.forRoot({
      strategies: [
        NbPasswordAuthStrategy.setup({
          name: 'email',

          baseEndpoint: environment.api,
          login: {
            // ...
            endpoint: 'login_v2/',
            method: 'post'
          },
          logout: {
            // ...
            endpoint: 'logout/',
            method: 'get'
          },
          register: {
            // ...
            endpoint: 'register',
          },
          token: {
            class: NbAuthSimpleToken,
            key: 'authentication_token',
          },
        }),
      ],
      forms: {},
    }),
    OwlDateTimeModule,
    NbDatepickerModule.forRoot(),
    NbMenuModule.forRoot(),
    PagesModule,
    NbToastrModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: false }),
  ],
  providers: [
    DataService,
    AuthGuard,
    UserService,
    MessagingService,
    AngularFireDatabase, AngularFireMessaging,
    {provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true},
    {
      provide: NB_AUTH_TOKEN_INTERCEPTOR_FILTER, useValue() {
        return false;
      }
    },
    {provide: APP_BASE_HREF, useValue: '/'}],
  bootstrap: [AppComponent],
  entryComponents: [
    RequestDialogComponent, ViewItemsComponent, EditDialogComponent
  ]
})
export class AppModule {
}
