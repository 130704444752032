import {ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {NbDialogService} from '@nebular/theme';
import {RequestDialogComponent} from './request-dialog/request-dialog.component';
import {interval, Subscription} from 'rxjs';
import {User} from '../../../common/models/user';
import {DataService} from '../../../services/data.service';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'app-merchant',
  templateUrl: './merchant.component.html',
  styleUrls: ['./merchant.component.scss'],
})
export class MerchantComponent implements OnInit, OnDestroy {

  types: any[] = [];
  @Output()
  showRiders: EventEmitter<{ points: any[], merchant: any }[]> = new EventEmitter();
  user: User;
  nearbyRiderIds: number[] = [];
  sub: Subscription;
  sub2: Subscription;
  active = false;

  constructor(private http: DataService, private dialogService: NbDialogService,
              private userService: UserService, private cd: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.user = this.userService.user;
    if (this.user.id) {
      this.getVehicleType().then();
    }
    this.getRiders(true).then();
    this.sub2 = this.userService.user$.subscribe(res => {
      this.user = res;
      this.getVehicleType().then();
      this.getRiders(true).then();
    });


  }

  async getRiders(status: boolean) {
    if (this.user && this.user.outlets) {
      let data = [];
      await this.user.outlets.forEach(async (outlet, index) => {
        try {
          const mapData = await this.http.query({lat: outlet.address.latitude, lng: outlet.address.longitude , outlet_id: outlet.id},
            'nearby_riders');
          const i = mapData.fields.indexOf('user_id');
          this.nearbyRiderIds = this.nearbyRiderIds.concat(mapData.points.map(p => p.fields[i]));
          const points = mapData.points;
          data = data.concat([{points, merchant: outlet}]);
          if (index + 1 === this.user.outlets.length) {
            this.showRiders.emit(data);
            console.log('emitted');
          }
        } catch (e) {
          console.error(e);
        }
      });
      this.active = true;

    }

  }

  async getVehicleType() {
    try {
      if (this.user.outlets && this.user.outlets.length >= 1) {
        this.user.outlets.forEach(o => {
          this.types = this.types.concat(o.categories.map(c => c.category_rate_list.category));
        });
        this.types = this.removeDuplicates(this.types, 'id');
        this.cd.detectChanges();
      }
    } catch (e) {
      console.error(e);
    }

  }

  removeDuplicates(myArr, prop) {
      return myArr.filter((obj, pos, arr) => {
          return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
      });
  }


  openRequestDialog(type) {
    this.dialogService.open(RequestDialogComponent, {context: {type, userIds: this.nearbyRiderIds}});

  }

  ngOnDestroy(): void {
    try {
      this.sub2.unsubscribe();
      this.sub.unsubscribe();
    } catch (e) {
    }

  }
}
