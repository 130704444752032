import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NbAuthService} from '@nebular/auth';
import {Observable, Subject} from 'rxjs';
import {Router} from '@angular/router';
import {User} from '../common/models/user';
import {HttpService} from '../common/config/http.service';
import {MessagingService} from './messaging.service';

@Injectable({
  providedIn: 'root',
})
export class UserService extends HttpService<any> {
  user: User = {id: null} as User;
  redirectUrl: string;
  statuses: any;

  user$: Subject<any> = new Subject() as Subject<any>;

  constructor(private httpClient: HttpClient, private router: Router, private nbAuth: NbAuthService,
              private messagingService: MessagingService ) {
    super(httpClient, {
      path: '/user',
    });

    this.nbAuth.isAuthenticatedOrRefresh().subscribe(() => {
      this.init().then();
    });
  }

  getUser$(): Observable<any> {
    return this.user$.asObservable();
  }

  async init() {
    try {
      const user = await this.getUser();
      this.user = user.data[0];
      if (this.user.role.name.indexOf('merchant') < 0) {
        this.logOut();
      }
      localStorage.setItem('user', JSON.stringify(this.user));
      this.user.statuses = this.user.statuses.sort((prev, next) => {
        if (prev.unique_id < next.unique_id) {
          return -1;
        } else if (prev.unique_id > next.unique_id) {
          return 1;
        } else {
          return 0;
        }
      });
      this.messagingService.requestPermission(this.user.id);
      this.user$.next(this.user);
    } catch (e) {
      console.error(e);
      this.logOut();
    }
  }

  async getUser(): Promise<any> {
    return this.query({__me__bool: true, __include: ['statuses', 'outlets']});
  }

  logOut() {
    this.nbAuth.logout('email').subscribe(() => {
      localStorage.removeItem('auth_app_token');
      location.reload();
    }, () => {
      localStorage.removeItem('auth_app_token');
      location.reload();
    });
  }

  isLoggedIn() {
    return true;
  }
}
