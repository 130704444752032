import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HttpService} from '../common/config/http.service';

@Injectable()
export class DataService extends HttpService<any> {

  constructor(private httpClient: HttpClient) {
    super(httpClient, {
      path: '',
    });
  }

}
