import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'ngx-user-info-dialog',
  templateUrl: './user-info-dialog.component.html',
  styleUrls: ['./user-info-dialog.component.scss'],
})
export class UserInfoDialogComponent implements OnInit {
  columns: any[] = [];
  customData: any;
  data: any[] = [];
  displayFn?: (row: any, column: any) => {};

  constructor() {
    this.columns =
      [ {
          name: 'Outlet Name',
          displayName: 'Outlet Name',
          displayFn: (row, c) => {
            if (row.outlets[0]) {
              return row.outlets[0].name;
            } else {
              return '';
            }
          },
        },
          {
              name: 'Address',
              displayName: 'Address',
              displayFn: function (row, c) {
                  if (row.outlets[0]) {
                      return row.outlets[0].address.address;
                  } else {
                      return '';
                  }
              },
          },
          {
              name: 'Google Address',
              displayName: 'Google Address',
              displayFn: (row, c) => {
                  if (row.outlets[0]) {
                      return row.outlets[0].address.google_address;
                  } else {
                      return '';
                  }
              },
          },
      ];
  }

  ngOnInit() {
    this.data.push(this.customData);
  }

}
