import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss'],
})
export class UserManagementComponent implements OnInit {
  tabs: any[] = [
    {
      title: 'Users',
      route: './user',
    },
    {
      title: 'Outlet',
      route: ['./outlet'],
    },
  ];
  constructor() { }

  ngOnInit() {
  }
}
