import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {UserComponent} from './user/user.component';
import {UserManagementComponent} from './user-management/user-management.component';
import {OutletComponent} from './outlet/outlet.component';

const routes: Routes = [
  {
    path: '',
    component: UserManagementComponent,
    children: [
      {
        path: 'user',
        component: UserComponent,
      },
      {
        path: 'outlet',
        component: OutletComponent,
      },
    ],
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UserManagementRoutingModule {
}
