import {Component, OnInit} from '@angular/core';
import {DataService} from '../../../../services/data.service';
import {ToastService} from '../../../../services/toast.service';
import {NbDialogRef} from '@nebular/theme';

@Component({
  selector: 'app-add-outlet',
  templateUrl: './add-outlet.component.html',
  styleUrls: ['./add-outlet.component.scss']
})
export class AddOutletComponent implements OnInit {

  data: any;

  constructor(private toastService: ToastService, private http: DataService,
              private ref: NbDialogRef<AddOutletComponent>) {
  }

  async ngOnInit() {
    // try {
    //   if (this.data.id) {
    //     const res = await this.http.query({__user_type_id__equal: this.data.id}, 'rate_master');
    //     this.data.rate_masters = res.data;
    //   } else {
    //     this.data.rate_masters = [];
    //   }
    //
    // } catch (e) {
    //   this.data.rate_masters = [];
    // }
  }

  cancel() {
    this.ref.close();
  }


  async submit() {
    try {
      if (this.data.id) {
        const res = await this.http.update(this.data.id, this.data, {}, 'outlet');
        this.toastService.showToast('Data Saved successfully', 'Saved', false);
      } else {
        const res = await this.http.create(this.data, {}, 'outlet');
        this.toastService.showToast('Data Saved successfully', 'Saved', false);
      }
      this.ref.close(true);
    } catch (e) {
      this.toastService.showToast('Something went wrong.Please try again.', 'Error', true);
    }
  }


  async addCategory($event, condition: string, index?: number) {
    if (condition === 'add') {
      await this.http.create({
        __action: 'add',
        outlet_id: this.data.id,
        category_rate_list_id: $event.id
      }, {}, 'outlet_category_rate');
      this.data.categories.push($event);
    } else {
      await this.http.create({
        __action: 'remove',
        outlet_id: this.data.id,
        category_rate_list_id: $event.id
      }, {}, 'outlet_category_rate');
      this.data.categories.splice(index, 1);
    }
  }
}
