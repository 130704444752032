import {Component, OnInit} from '@angular/core';
import {NbMenuItem} from '@nebular/theme';
import {NgxFreshChatService} from 'ngx-freshchat';
import {UserService} from '../../services/user.service';
import {User} from '../../common/models/user';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})
export class PageComponent implements OnInit {


  menu: NbMenuItem[] = [

    {
      title: 'Users',
      icon: 'person-outline',
      link: '/app/users/user',
    },
    {
      title: 'Map',
      icon: 'globe-2-outline',
      link: '/app/map',
    },
    {
      title: 'Jobs',
      icon: 'briefcase-outline',
      link: '/app/orders',
    },
    {
      title: 'Order Report',
      icon: 'bar-chart-outline',
      link: '/app/order-report',
    },

  ];

  user: User;
  constructor(private chat: NgxFreshChatService, private userService: UserService) {
    this.user = this.userService.user;
    if (this.user.id) {
      this.chatInit();
    }
    this.userService.user$.subscribe(res => {
      this.user = res;
      this.chatInit();
    });
  }

  chatInit() {
    this.chat.init({
      token: '6400358f-4ee3-4758-9f0e-126221248251',
      host: 'https://wchat.freshchat.com',
      tags: ['merchant'],
      firstName: this.user.name,
      lastName: '',
      email: this.user.email,
      phone: this.user.phone,
    })
      .subscribe(
        () => console.log('FreshChat is ready!')
      );
  }

  ngOnInit() {
  }

}
