
export const environment = {
  production: false,
  api: 'https://liveapi.jeeblynow.com/api/v1/',
 //  api: 'http://192.168.0.130:5000/api/v1/',
  // api: 'http://127.0.0.1:5000/api/v1/'
  firebaseConfig: {
    apiKey: 'AIzaSyDuJLvwZUnyDN9VcPXiEv-nusqtRDu--Fs',
    authDomain: 'jeeblyllc-c21d0.firebaseapp.com',
    databaseURL: 'https://jeeblyllc-c21d0.firebaseio.com',
    projectId: 'jeeblyllc-c21d0',
    storageBucket: 'jeeblyllc-c21d0.appspot.com',
    messagingSenderId: '741401794409',
    appId: '1:741401794409:web:33716a986eb7a1b6',
  }
};

