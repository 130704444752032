import {Component, OnInit} from '@angular/core';
import {NbDialogService} from '@nebular/theme';
import {AddOutletComponent} from './add-outlet/add-outlet.component';

@Component({
  selector: 'app-outlet',
  templateUrl: './outlet.component.html',
  styleUrls: ['./outlet.component.scss']
})
export class OutletComponent implements OnInit {
  refresh: boolean = false;
  columns = [
    {
      name: 'name',
      displayName: 'Name',
    },
    {
      name: 'branch',
      displayName: 'Branch',
      displayFn: (r => r.branch ? r.branch.name : ''),
    },

  ];

  constructor(private nbDialogService: NbDialogService) {
  }

  ngOnInit() {
  }

  edit(event) {
    this.refresh = false;
    const _dialog = this.nbDialogService.open(AddOutletComponent,
      {context: {data: Object.assign({}, event)}});
    _dialog.onClose.subscribe(res => {
      if (res) {
        this.refresh = true;
      }
    });
  }
}
