import {Component, ElementRef, NgZone, OnInit, ViewChild} from '@angular/core';
import {MapsAPILoader} from '@agm/core';
import {NbDialogRef} from '@nebular/theme';
import {NgxCoolDialogsService} from 'ngx-cool-dialogs';
import {DataService} from '../../../../services/data.service';
import {ToastService} from '../../../../services/toast.service';
import {UserService} from '../../../../services/user.service';
import {ajax} from 'rxjs/ajax';
import { HttpClient } from '@angular/common/http';

declare var google: any;

@Component({
  selector: 'app-request-dialog',
  templateUrl: './request-dialog.component.html',
  styleUrls: ['./request-dialog.component.scss'],
})
export class RequestDialogComponent implements OnInit {
  step = 1;
  type: any;
  loading: boolean = false;
  addresses: any[] = [];
  address: any = {address: null, google_address: null};
  customer: any = {name: null, phone: null};
  orderData: any = {};
  start: Date;
  end: Date;
  startDate: Date = new Date();
  showMerchantForm = false;
  merchants: any[] = [];
  riders: any[] = [];
  types: any[] = [];
  riderTypes: any[] = [];
  userIds: number[] = [];
  user: any = {is_premium: false};
  viewerOptions: any = {
    navbar: false,
    inline: true,
    toolbar: {
      zoomIn: 4,
      zoomOut: 4,
      oneToOne: 4,
      reset: 4,
      prev: 4,
      play: {
        show: 4,
        size: 'large',
      },
      next: 4,
      rotateLeft: 4,
      rotateRight: 4,
      flipHorizontal: 4,
      flipVertical: 4,
    },
  };
  outletId: number;
  outlet: any;
  distance: number;
  eta: number;

  @ViewChild('search', {static: true})
  public searchElementRef: ElementRef;

  constructor(private http: DataService, private mapsAPILoader: MapsAPILoader, private userService: UserService,
              private ngZone: NgZone, private toast: ToastService, public ref: NbDialogRef<RequestDialogComponent>,
              private coolDialogs: NgxCoolDialogsService, private httpClient: HttpClient) {
    this.orderData = {
      payment_type: null,
      order_no: null,
      type_id: null,
      customer: {name: null, phone: null},
      is_express: null,
      is_cold: null,
      customer_id: null,
      delivery_address_id: undefined,
      delivery_address: undefined,
      field1: null,
    };
    this.user = this.userService.user;
    if (!this.orderData.id) {
      this.autoGenerateOrderNo();
    }

  }

  async getGRL(grl: string) {
    console.log(grl);
    try {
      this.httpClient.get('https://grl.ae/addressapi/GrlApiWithAddr.php', {
        params: {
          grl,
          access_token: 'WVKNhEw8fLV9SK3rbCYU7Yq5z528fhUP'
        },
        headers: {referer: 'www.jeeblynow.com'}
      }).subscribe((res: any) => {
        this.address.address = '${res.buildingname}, ${res.postedname}, ${res.street}, ${res.area}';
        this.address.latitude = res.lat;
        this.address.longitude = res.lon;
        this.address.city = res.city;
      });

    } catch (e) {
      console.error(e);
    }
  }

  async getAvailability() {
    try {
      await this.http.query({
        __category_id__equal: this.type.id, __only: 'id,category,user_id',
        __user_id__in: this.userIds.join(','),
      }, 'user_category_association').then(res => {
        if (!res) {
          this.coolDialogs.alert('Riders not available!').subscribe(resul => {
            this.ref.close();
          });
        }
      });
    } catch (e) {
      this.coolDialogs.alert('Riders not available!').subscribe(resul => {
        this.ref.close();
      });
    }
  }

  async ngOnInit() {
    // console.log(this.type, this.userIds);
    if (this.type) {
      this.orderData.category_id = this.type.id;
      this.getAvailability().then();
    }

    if (this.orderData.customer_id) {
      this.getCustomerAddresses(this.orderData.customer_id);
      this.customer = this.orderData.customer;
    }

    this.mapsAPILoader.load().then(() => {
      const autoComplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        componentRestrictions: {country: ['ae']},
        types: [],
      });
      const self = this;
      autoComplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          const place: any = autoComplete.getPlace();
          self.address.google_address = this.searchElementRef.nativeElement.value;
          // self.address.google_address = place.formatted_address;
          self.address.latitude = place.geometry.location.lat().toString();
          self.address.longitude = place.geometry.location.lng().toString();
        });
      });
    });
    if (this.showMerchantForm) {
      this.getMerchantInfo().then();
      // this.getRiderInfo().then();
    }
  }

  setOrderType(isDuty: boolean) {
    this.step += 1;
    this.orderData.is_duty = isDuty;
  }

  updateAddress() {
    this.orderData.delivery_address = this.addresses.find(add => add.id === parseInt(this.orderData.delivery_address_id, 10));
  }

  goBack() {
    this.step -= 1;
  }

  autoGenerateOrderNo() {
    this.orderData.order_no = this.create_UUID();
  }

  async getETA(): Promise<any> {
    if (this.orderData.delivery_address_id || this.address.google_address) {
      let end;
      if (this.orderData.delivery_address_id) {
        end = [this.orderData.delivery_address.latitude, this.orderData.delivery_address.longitude];
      } else {
        end = [this.address.latitude, this.address.longitude];
      }

      const start = [this.outlet.address.latitude, this.outlet.address.longitude];
      const res = await ajax.get('https://route.api.here.com/routing/7.2/calculateroute.json?app_id=3q6YMiPOxqoVKQ1BRjAd' +
        '&app_code=ODDcu6rXVrwomGzCToqfTg&waypoint0=geo!' + start + '&waypoint1=geo!' + end +
        '&mode=fastest;car;traffic:enabled', {}).toPromise();
      this.distance = res.response.response.route[0].summary.distance;
      this.eta = res.response.response.route[0].summary.travelTime;
    }
  }

  async getMerchantInfo() {
    const res = await this.http.query({__is_merchant__bool: true}, 'user');
    this.merchants = res.data;
  }

  async setMerchantTypes(event) {
    const merchant = event;
    this.orderData.merchant_id = merchant.id;
    if (this.orderData.user_id) {
      return;
    }
    const branchId = merchant.branch_id;
    this.getMerchantTypes(branchId).then();
  }

  async getMerchantTypes(branchId: number) {
    try {
      const res = await this.http.query({__branch_id__equal: branchId}, 'rate_master');
      if (res.hasOwnProperty('data')) {
        this.types = res.data.map(r => r.user_type);
      }
    } catch (e) {
      console.error(e);
    }
  }

  // async getRiderInfo() {
  //   const res = await this.http.query({}, 'user');
  //   this.riders = res.data;
  // }

  async request() {
    this.loading = true;
    let addressId: number = null;
    let customerId: number = this.orderData.customer_id;
    this.orderData.outlet_id = this.outletId;
    try {
      if (!this.orderData.delivery_address_id && this.address.address) {
        const address = await this.http.create(this.address, {__only: 'id'}, 'address');
        addressId = address[0].id;
        this.orderData.delivery_address_id = addressId;
      }
      if (!this.orderData.customer_id && this.customer.name) {
        const customer = await this.http.create(this.customer, {__only: 'id'}, 'customer');
        customerId = customer[0].id;
        this.orderData.customer_id = customerId;
      }
      if (customerId && addressId) {
        await this.http.create({__action: 'add', customer_id: customerId, address_id: addressId},
          {}, 'customer_address');
      }
      if (this.orderData.id) {
        await this.http.update(this.orderData.id, this.orderData, {__only: 'id'}, 'order');
      } else {
        await this.http.create(this.orderData, {__only: 'id'}, 'order');
      }
      this.toast.showToast('Rider Requested Successfully', 'Success', false);
      this.ref.close();
      this.loading = false;
    } catch (err) {
      this.toast.showToast('Error creating request', 'Error', true);
      console.error(err);
      this.loading = false;
    }
  }

  async schedule() {
    this.orderData.order_no = this.create_UUID();
    this.orderData.duty_start_time = this.start.toJSON();
    this.orderData.duty_end_time = this.end.toJSON();
    this.orderData.outlet_id = this.outletId;

    try {
      if (this.orderData.id) {
        await this.http.update(this.orderData.id, this.orderData, {__only: 'id'}, 'order');
      } else {
        await this.http.create(this.orderData, {__only: 'id'}, 'order');
      }
      this.toast.showToast('Rider Scheduled Successfully', 'Success', false);
      this.ref.close();
    } catch (err) {
      this.toast.showToast('Error creating request', 'Error', true);
      console.error(err);
    }
  }

  create_UUID() {
    let dt = new Date().getTime();
    return 'xxxyxyxxyxxx'.replace(/[xy]/g, c => {
      // tslint:disable-next-line:no-bitwise
      const r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      // tslint:disable-next-line:no-bitwise
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }

  setTypes(event: any) {
    if (typeof event === typeof 'str') {
      return;
    }
    this.orderData.user_id = event.id;
    this.riderTypes = event.types;
  }

  setCustomer(event) {
    if (event) {
      this.orderData.customer_id = event.id;
      this.customer = event;
      this.getCustomerAddresses(event.id).then();
    } else {
      this.orderData.customer_id = undefined;
      this.orderData.delivery_address_id = undefined;
      this.orderData.customer = {name: null, phone: null};
    }
  }

  async getCustomerAddresses(id: number) {
    const res = await this.http.get(id, {__only: ['addresses'], __id__equal: id}, 'customer');
    this.addresses = res['addresses'];
  }

  cancel() {
    this.ref.close();
  }

  clearAddress() {
    if (this.orderData.delivery_address_id === 'undefined') {
      this.orderData.delivery_address_id = undefined;
    }
  }

  getFormValidity() {
    // if (!this.user.is_premium) {
    //   if (!this.orderData.customer_id && (!this.customer.name || !this.customer.phone)) {
    //     return true;
    //   } else {
    //     if (!this.orderData.delivery_address_id && (!this.address.address || this.address.google_address)) {
    //       return true;
    //     }
    //   }
    // }
    return false;
  }
}
