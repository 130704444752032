import {NgModule} from '@angular/core';

import {PagesRoutingModule} from './pages-routing.module';
import {PageComponent} from './page/page.component';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import {LeafletDrawModule} from '@asymmetrik/ngx-leaflet-draw';
import {MapModule} from './map/map.module';
import {OrderManagementModule} from './order-management/order-management.module';
import {SharedModule} from '../common/shared.module';
import {UserManagementModule} from './user-management/user-management.module';
import { OrderReportComponent } from './order-report/order-report.component';


@NgModule({
  declarations: [PageComponent, OrderReportComponent],
  imports: [
    PagesRoutingModule,
    LeafletModule.forRoot(),
    LeafletDrawModule.forRoot(),
    MapModule,
    OrderManagementModule,
    UserManagementModule,
    SharedModule
  ],
})
export class PagesModule {
}
