import {ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit} from '@angular/core';
// import 'leaflet.awesome-markers';
import 'leaflet-rotatedmarker';
import * as L from 'leaflet';
import {icon, LayerGroup, marker} from 'leaflet';
import {NbDialogService} from '@nebular/theme';
import 'leaflet-draw';
import 'leaflet.measurecontrol';
import 'leaflet-easybutton';
import 'leaflet-control-geocoder';
import 'leaflet.fullscreen';
import {ajax} from 'rxjs/ajax';
import {interval, Subscription} from 'rxjs';
import {DataService} from '../../../services/data.service';
import {UserService} from '../../../services/user.service';
import {User} from '../../../common/models/user';
import {RequestDialogComponent} from '../merchant/request-dialog/request-dialog.component';
import {getDistance} from 'geolib';

@Component({
  selector: 'app-map-container',
  templateUrl: './map-container.component.html',
  styleUrls: ['./map-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapContainerComponent implements OnInit, OnDestroy {

  eta = 0;
  distance = 0;
  merchantSet = false;
  map: L.Map;
  markers: any = {};
  path: Subscription;
  options = {
    layers: [
      L.tileLayer('https://2.base.maps.api.here.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/512/' +
        'png8?app_id=rsGQKlltPzE8cIBMW0pY&app_code=LzSMGkzTS28ZP4jc8ju68A&ppi=320', {
        maxZoom: 18,
        attribution: '',

      }),
    ],
    // measureControl: true,
    fullscreenControl: true,
    zoomControl: true,
    zoom: 10,
    center: L.latLng(24.031505, 53.788576),
  };

  layerMainGroup: LayerGroup[];

  sub: Subscription;
  sub2: Subscription;
  user: User;
  selectedOutlet: any;
  outlet: any = {};
  types: any[] = [];
  isVisible = true;
  private courses: any[];

  constructor(private nbDialogService: NbDialogService, private http: DataService,
              private cd: ChangeDetectorRef, private userService: UserService) {

    this.user = this.userService.user;

    this.sub = this.userService.user$.subscribe(res => {
      this.user = res;
      this.setMerchantLocation();
      this.merchantNearByRiders().then();
    });

    this.sub2 = interval(15000)
      .subscribe(() => {
        if (this.isVisible) {
          this.merchantNearByRiders().then();
        }
      });

  }

  ngOnInit() {
  }

  onMapReady(map: L.Map) {
    this.map = map;
    this.setMerchantLocation();
    this.merchantNearByRiders().then();
  }


  setMerchantLocation() {
    if (this.user.outlets) {
      const markers = [];
      this.user.outlets.forEach(outlet => {
        this.outlet[outlet.id] = marker([outlet.address.latitude, outlet.address.longitude], {
          alt: outlet,
          icon: icon({
            iconSize: [32, 32],
            iconAnchor: [16, 32],
            iconUrl: 'assets/images/merchant.png',
            className: 'merchantMarker'
          }),
          zIndexOffset: 10000
        }).bindTooltip(outlet.name,
          {permanent: false, direction: 'top', className: 'whiteToolTip'}).openTooltip()
          .on('click', this.markerClick.bind(this)).addTo(this.map);
        markers.push(this.outlet[outlet.id]);
      });

      this.map.fitBounds(L.latLngBounds(markers.map(m => m.getLatLng())));
      this.cd.detectChanges();
    }
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    console.log('on focus', this.isVisible, event);
    this.isVisible = true;
  }

  @HostListener('window:blur', ['$event'])
  onBlur(event: any): void {
    console.log('on blur', this.isVisible, event);
    this.isVisible = false;
  }

  @HostListener('document:visibilitychange', ['$event'])
  handleVisibilityChange(event: any): void {

    if (!document.hidden) {
      this.isVisible = true;
      this.merchantNearByRiders().then();
      console.log('I am in here', this.isVisible, event);
    } else {
      this.isVisible = false;
      console.log('I am outside', this.isVisible, event);
    }
  }

  async getETA(start: string, end: string): Promise<any> {
    return new Promise(resolve => ajax.get('https://route.api.here.com/routing/7.2/calculateroute.json?app_id=rsGQKlltPzE8cIBMW0pY' +
      '&app_code=LzSMGkzTS28ZP4jc8ju68A&waypoint1=geo!' + start + '&waypoint0=geo!' + end +
      '&mode=fastest;car;traffic:enabled', {}).subscribe(res => resolve(res.response)));
  }

  markerClick(event) {
    this.selectedOutlet = event.target.options.alt;
    this.cd.detectChanges();
  }

  async merchantNearByRiders() {
    const itemsLevel1: LayerGroup = new LayerGroup();

    if (this.user.outlets) {
      await this.user.outlets.forEach(async (outlet) => {
        const mapData = await this.http.query({
            lat: outlet.address.latitude,
            lng: outlet.address.longitude,
            outlet_id: outlet.id
          },
          'nearby_riders');
        const i = mapData.fields.indexOf('user_id');
        this.outlet[outlet.id].nearbyRiderIds = mapData.points.map(p => p.fields[i]);
        const courseIndex = mapData.fields.indexOf('course');
        this.courses = mapData.points.map(p => p.fields[courseIndex]);

        const points = mapData.points;
        let distance = 0;
        let eta = 0;
        try {
          this.outlet[outlet.id].unbindTooltip();
          // this.outlet[outlet.id].bindTooltip(outlet.id + ' ' + outlet.name,
          //   {permanent: false, direction: 'top', className: 'whiteToolTip'});
          // this.outlet[outlet.id].bindTooltip(outlet.id + ' ' + outlet.name,
          //   {permanent: false, direction: 'top', className: 'whiteToolTip'});
          this.outlet[outlet.id].bindPopup(outlet.name);

          const checkDistance = getDistance(
            {latitude: points[0].point.lat, longitude: points[0].point.lon},
            {latitude: outlet.address.latitude, longitude: outlet.address.longitude}
          );

          if (checkDistance < 100) {
            distance = 100;
            eta = 60;
          } else if (checkDistance > 100 && checkDistance < 300) {
            distance = 250;
            eta = 120;
          } else {
            const [res] = await Promise.all([this.getETA([points[0].point.lat, points[0].point.lon].join(','),
              [outlet.address.latitude, outlet.address.longitude].join(','))]);
            distance = res.response.route[0].summary.distance;
            eta = res.response.route[0].summary.travelTime;
          }
          this.outlet[outlet.id].bindTooltip('' + (eta / 60).toFixed(0) + ' min (' + (distance / 1000).toFixed(0) + ' Km)',
            {permanent: true, direction: 'bottom', className: 'tooltipStyle'}).openTooltip();

        } catch (e) {
          this.outlet[outlet.id].bindTooltip('No Rider available',
            {permanent: true, direction: 'bottom', className: 'tooltipStyle'}).openTooltip();
          // console.error(e);
        }
        points.forEach((p, index) => {

          return marker([p.point.lat, p.point.lon], {
            rotationAngle: Math.round(this.courses[index]),
            icon: icon({
              iconSize: [18, 46],
              iconAnchor: [9, 46],
              iconUrl: 'assets/images/rider.png',
            }),
          }).addTo(itemsLevel1);
        });
      });
      this.layerMainGroup = [itemsLevel1];
      this.cd.detectChanges();
    }
  }

  async getAdress(lat, lng) {
    const arr = [];
    arr.push({id: 0, lat, lng});
    return new Promise(resolve =>
      ajax.post('https://bugs.roadcast.co.in:8089/reverse',
        arr, {'content-type': 'application/json'}).subscribe(res => resolve(res.response)));
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
    this.sub2.unsubscribe();
  }


  openRequestDialog(type) {
    const dialog = this.nbDialogService.open(RequestDialogComponent, {
      context: {
        type,
        userIds: this.outlet[this.selectedOutlet.id].nearbyRiderIds, outletId: this.selectedOutlet.id, outlet: this.selectedOutlet
      }
    });
    dialog.onClose.subscribe(() => {
      this.merchantNearByRiders().then();
    });
  }

}

