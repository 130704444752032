import {HostListener, Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {interval, Subject, Subscription} from 'rxjs';
import {BnNgIdleService} from 'bn-ng-idle';
import {HttpService} from '../common/config/http.service';

export class OrderService extends HttpService<any> implements OnDestroy {

  orders$: Subject<any> = new Subject() as Subject<any>;
  orders: any[] = [];
  outletId: any;
  public lastUpdatedOn: Date = new Date();
  private initial = true;
  sub: Subscription;
  // sub2: Subscription;

  constructor(http: HttpClient, private bnIdle: BnNgIdleService) {
    super(http, {
      path: 'order',
    });

    this.init().then();
  }

  resetTime() {
    this.lastUpdatedOn = new Date();
    this.lastUpdatedOn.setHours(this.lastUpdatedOn.getHours() - 6);
  }

  @HostListener('window:beforeunload')
  ngOnDestroy(): void {
    try {
      // this.sub2.unsubscribe();
      this.sub.unsubscribe();
    } catch (e) {
    }
  }

  async init() {
    this.resetTime();
    try {
      this.sub.unsubscribe();
    } catch (e) {

    }
    const orders = await this.getOrders();
    this.orders = orders.orders;
    this.orders$.next(this.orders);
    this.sub = interval(15000)
      .subscribe(() => {
        this.getOrders().then((res) => {
          this.orders$.next(res.orders);
        });
      });
  }

  async getOrders(): Promise<{ orders: any[], initial: boolean }> {
    const currentTime: Date = new Date();
    currentTime.setSeconds(currentTime.getSeconds() - 30);
    return new Promise(async (resolve, reject) => {
      try {
        // const query =
        let res;

        if (this.outletId) {
            res = await this.query({
                last_updated_on: this.lastUpdatedOn.toJSON(), __outlet_id__equal: this.outletId,
            }, 'order_polling');
        } else {
            res = await this.query({
                last_updated_on: this.lastUpdatedOn.toJSON(),
            }, 'order_polling');
        }
        this.lastUpdatedOn = currentTime;
        resolve({orders: res.data, initial: this.initial});
        this.initial = false;
      } catch (e) {
        reject(e);
        console.error(e);
      }
    });
  }

}
