import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {MapRoutingModule} from './map-routing.module';
import {MapContainerComponent} from './map-container/map-container.component';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import {NbDialogModule, NbWindowModule} from '@nebular/theme';
import {LeafletDrawModule} from '@asymmetrik/ngx-leaflet-draw';
import {MerchantComponent} from './merchant/merchant.component';
import {OwlDateTimeModule, OwlNativeDateTimeModule} from 'ng-pick-datetime';
import {MomentModule} from 'angular2-moment';
import {LeafletMarkerClusterModule} from '@asymmetrik/ngx-leaflet-markercluster';
import {SharedModule} from '../../common/shared.module';


@NgModule({
  declarations: [MapContainerComponent,
    MerchantComponent],
  imports: [
    CommonModule,
    MapRoutingModule,
    LeafletModule.forRoot(),
    LeafletDrawModule.forRoot(),
    NbWindowModule.forChild(),
    NbDialogModule.forChild(),
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MomentModule,
    LeafletMarkerClusterModule,
    SharedModule,
  ],
})
export class MapModule {
}
