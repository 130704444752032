import { Component, OnInit } from '@angular/core';
import {Column} from '../../common/data-table/data-table.component';

@Component({
  selector: 'app-order-report',
  templateUrl: './order-report.component.html',
  styleUrls: ['./order-report.component.scss']
})
export class OrderReportComponent implements OnInit {
  columns: Column[] = [
    {
      name: 'id',
      displayName: 'ID',
    },
    {
      name: 'order_no',
      displayName: 'Order No',
    },
    {
      name: 'payment_type',
      displayName: 'Payment Type',
    },
    {
      name: 'date',
      displayName: 'Date',
    },
    {
      name: 'order_time',
      displayName: 'Time',
    },
    {
      name: 'outlet',
      displayName: 'Outlet',
    },
    {
      name: 'branch',
      displayName: 'Branch',
    },
    {
      name: 'rider',
      displayName: 'Rider',
    },
    {
      name: 'customer',
      displayName: 'Customer',

    },

    {
      name: 'drop_address',
      displayName: 'Customer Address',

    },
    {
      name: 'pick_up_address',
      displayName: 'Outlet Address',

    },
    {
      name: 'final_status',
      displayName: 'Final Status',

    },
    {
      name: 'order_amount',
      displayName: 'Order Amount',

    },
    {
      name: 'created_by',
      displayName: 'Created By',

    },


    {
      name: 'order_accept',
      displayName: 'Accepted At',

    },
    {
      name: 'order_accept_location',
      displayName: 'Location',

    },
    {
      name: 'reach_merchant',
      displayName: 'Reach Merchant',

    },
    {
      name: 'reach_merchant_location',
      displayName: 'Location',

    },

    {
      name: 'order_dispatch',
      displayName: 'Dispatched At',

    },
    {
      name: 'order_dispatch_location',
      displayName: 'Location',

    },
    {
      name: 'reach_customer',
      displayName: 'Reach Customer',

    },
    {
      name: 'reach_customer_location',
      displayName: 'Location',

    },

    {
      name: 'order_delivered',
      displayName: 'Delivered At',

    },
    {
      name: 'order_delivered_location',
      displayName: 'Location',

    },
    {
      name: 'merchant_eta',
      displayName: 'Merchant ETA',

    },
    {
      name: 'reach_merchant_ata',
      displayName: 'Merchant ATA',

    },
    {
      name: 'drop_off_eta',
      displayName: 'Customer ETA',

    },
    {
      name: 'reach_customer_ata',
      displayName: 'Customer ATA',

    },
    {
      name: 'time_taken',
      displayName: 'Actual Time Taken',

    },
    {
      name: 'google_pick_up_eta',
      displayName: 'Google Pick Up Eta',

    },
    {
      name: 'google_drop_off_eta',
      displayName: 'Google Drop of ETA',

    },
    {
      name: 'google_pick_up_distance',
      displayName: 'Google Pick Up Distance',

    },
    {
      name: 'google_drop_off_distance',
      displayName: 'Google Drop Of Distance',

    },
    {
      name: 'distance',
      displayName: 'Actual Distance',

    },
    {
      name: 'reason',
      displayName: 'Reason',

    },
    {
      name: 'cost_to_merchant',
      displayName: 'Cost To Merchant',

    },
  ];

  searchFields = [
    {name: 'Order No', value: '__order_no__contains', term: undefined},
    {name: 'Outlet', value: '__outlet_name__starts_with', term: undefined},
    {name: 'Customer Name', value: '__customer_name__starts_with', term: undefined},
    {name: 'Customer Phone', value: '__customer_phone__starts_with', term: undefined},
  ];

  constructor() {

  }

  ngOnInit() {
  }

}
