import {Component, Input} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';
import {DataService} from '../../../../services/data.service';
import {ToastService} from '../../../../services/toast.service';

@Component({
  selector: 'app-edit-dialog',
  templateUrl: './edit-dialog.component.html',
  styleUrls: ['./edit-dialog.component.scss'],
})
export class EditDialogComponent {
  @Input()
  orderData;
  title: string;
  type: any;
  types: any[] = [];
  riders: any[] = [];
  riderTypes: any[] = [];
  start: Date;
  end: Date;
  startDate: Date = new Date();
  constructor(protected ref: NbDialogRef<EditDialogComponent>, private http: DataService, private toast: ToastService) {
    this.title = 'Edit Here';
  }

  cancel() {
    this.ref.close();
  }

  async setMerchantTypes(event) {
    const merchant = event;
    this.orderData.merchant_id = merchant.id;
    if (this.orderData.user_id) {
      return;
    }
    const branchId = merchant.branch_id;
    try {
      const res = await this.http.query({__branch_id__equal: branchId}, 'rate_master');
      if (res.hasOwnProperty('data')) {
        this.types = res.data.map(r => r.user_type);
      }
    } catch (e) {
      console.error(e);
    }
  }

  setTypes(event) {
    if (typeof event === typeof 'str') {
      return;
    }
    this.orderData.user_id = event.id;
    const types = this.riders.find(r => r.id === this.orderData.user_id);
    if (types) {
      this.riderTypes = types.types;
    }
  }

  async request() {
    try {
      await this.http.update(this.orderData.id, this.orderData, {__only: 'id'}, 'order');
      this.toast.showToast('Rider Requested Successfully', 'Success', false);
      this.ref.close();
    } catch (err) {
      this.toast.showToast('Error creating request', 'Error', true);
      console.error(err);
    }
  }

  async schedule() {
    // this.orderData.order_no = this.create_UUID();
    this.orderData.duty_start_time = this.start.toJSON();
    this.orderData.duty_end_time = this.end.toJSON();

    try {
      await this.http.create(this.orderData, {__only: 'id'}, 'order');
      this.toast.showToast('Rider Scheduled Successfully', 'Success', false);
    } catch (err) {
      this.toast.showToast('Error creating request', 'Error', true);
      console.error(err);
    }
  }
}
