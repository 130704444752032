import {ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {NbDialogService} from '@nebular/theme';

import {RequestDialogComponent} from '../../map/merchant/request-dialog/request-dialog.component';
import {ViewItemsComponent} from './view-items/view-items.component';
import {Subscription} from 'rxjs';
import * as L from 'leaflet';
import {icon, LayerGroup, marker} from 'leaflet';
import {OrderService} from '../../../services/order.service';
import {Order} from '../../../common/models/order';
import {DataService} from '../../../services/data.service';
import {UserService} from '../../../services/user.service';
import {User} from '../../../common/models/user';
import * as moment from 'moment-timezone';


@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [OrderService],
})
export class OrdersComponent implements OnInit, OnDestroy {
  orders: any[] = [];
  page: any;
  totalPages: any;
  currPage: number;
  limit = 25;
  order: any;
  outletId: number;
  user: any;
  placeholders = [];
  loading = false;
  pickUpMarker: any;
  deliveryMarker: any;
  isMapReady = false;
  public lastUpdatedOn: Date = new Date();
  layerGroup: LayerGroup[];
  map: L.Map;
  options = {
    layers: [
      L.tileLayer('https://2.base.maps.api.here.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/512/' +
        'png8?app_id=3q6YMiPOxqoVKQ1BRjAd&app_code=ODDcu6rXVrwomGzCToqfTg&ppi=320', {
        maxZoom: 18,
        minZoom: 8,
        attribution: '',
      }),
    ],
    zoomControl: true,
    fullscreenControl: true,
    center: L.latLng(24.55555, 55.343),
  };
  mygreenicon = {
    icon: L.icon({
      iconSize: [25, 25],
      iconAnchor: [20, 40],
      iconUrl: 'assets/images/merchant.png',
    }),
  };
  myredicon = {
    icon: L.icon({
      iconSize: [40, 40],
      iconAnchor: [20, 40],
      iconUrl: 'assets/images/customer.png',
    }),
  };
  tabs: any[] = [
    {name: 'UNACCEPTED', statusCode: [0, 100], count: 0},
    {name: 'ACCEPTED', statusCode: [101, 200], count: 0},
    {name: 'REACHED', statusCode: [201, 250], count: 0},
    {name: 'DISPATCHED', statusCode: [251, 300], count: 0},
    {name: 'REACHED CUSTOMER', statusCode: [301, 350], count: 0},
    {name: 'DELIVERED', statusCode: [351, 900], count: 0},
    {name: 'CANCELLED', statusCode: [-1, -100], count: 0},
  ];
  delays: any = {
    order_create: {warn: 2, alert: 3},
    order_accept: {warn: 12, alert: 16},
    reach_merchant: {warn: 20, alert: 25},
    order_dispatch: {warn: 40, alert: 60},
  };
  activeTab: any = this.tabs[0];
  orderList: Order[] = [];
  sub: Subscription;
  sub2: Subscription;
  baseUrl: any = 'https://roadcast.sgp1.digitaloceanspaces.com/upload/';
  startDate: Date = moment(moment().format('YYYY-MM-DD') + ' 00:00:01').tz('UTC').format('YYYY-MM-DDTHH:mm:ss');
  endDate: Date = moment(moment().format('YYYY-MM-DD') + ' 23:59:59').tz('UTC').format('YYYY-MM-DDTHH:mm:ss');

  constructor(private nbDialogService: NbDialogService,
              private userService: UserService,
              private orderService: OrderService,
              private cd: ChangeDetectorRef,
              private http: DataService) {
    this.currPage = 1;
    this.totalPages = 0;
  }

  async ngOnInit() {
    // this.loadNext().then();
    this.user = this.userService.user;
    this.sub = this.userService.user$.subscribe(res => this.user = res);

    this.sub2 = this.orderService.orders$.subscribe((res: any[]) => {
      this.setOrders(res);
    });
  }

  @HostListener('document:visibilitychange', ['$event'])
  handleVisibilityChange(event: any): void {

    if (!document.hidden) {
      console.log('I am in here');
      this.orderService.init();
    } else {
      this.orderService.sub.unsubscribe();
      console.log('I am outside');
    }
  }

  // centerLeafletMapOnMarker(map, marker) {
  //   const latLngs = [marker.getLatLng()];
  //   const markerBounds = L.latLngBounds(latLngs);
  //   map.fitBounds(markerBounds);                 // zooms on the marker
  //   map.setZoom(12);
  // }

  onMapReady(map: L.Map) {
    if (!this.isMapReady) {
      this.map = map;
      this.isMapReady = true;
      if (this.order) {
        this.setMap();
      }
    }
  }

  setOrder(data: any) {
    if (this.order && this.order.id === data.id) {
      this.order = {} as Order;
      this.isMapReady = false;
      this.map.off();
      this.map.remove();
      return;
    }
    this.order = data;
    if (this.isMapReady) {
      this.setMap();
    }
  }

  async setMap() {
    const itemsLevel_1: LayerGroup = new LayerGroup();

    const markers = [];
    if (this.order.pick_up_address && this.order.pick_up_address.latitude !== 0 && this.order.pick_up_address.longitude !== 0) {
      this.pickUpMarker = L.marker([this.order.pick_up_address.latitude, this.order.pick_up_address.longitude],
        this.mygreenicon).bindPopup('Pick Up').addTo(itemsLevel_1);
      markers.push(this.pickUpMarker);
    }
    if (this.order.delivery_address && this.order.delivery_address.latitude !== 0 && this.order.delivery_address.longitude !== 0) {
      this.deliveryMarker = L.marker([this.order.delivery_address.latitude, this.order.delivery_address.longitude],
        this.myredicon).bindPopup('Drop Off').addTo(itemsLevel_1);
      markers.push(this.deliveryMarker);
    }
    this.order.order_status.filter(s => s.lat && s.lng).forEach((s, index) => {
      markers.push(marker([s.lat, s.lng], {
        // icon: redMarker,
        icon: icon({
          iconSize: [29, 30],
          iconAnchor: [13, 46],
          iconUrl: 'assets/images/' + s.status_name + '.png',
          // shadowUrl: 'assets/images/marker-shadow.png',
        }),
      }).bindTooltip((index + 1).toString() + '. ' + s.status_name,
        {permanent: false, direction: 'bottom', className: 'tooltipStyle'})
        .openTooltip().addTo(itemsLevel_1));
    });

    if (this.order.user) {
      try {
        const res = await this.http.query({
          __user_id__equal: this.order.user.id, __only: ['lat', 'lng',
            'fixed_time', 'course'],
        }, 'user_device');
        const data = res.data[0];
        marker([data.lat, data.lng], {
          // icon: redMarker,
          rotationAngle: Math.round(data.course),
          icon: icon({
            iconSize: [18, 46],
            iconAnchor: [13, 46],
            iconUrl: 'assets/images/rider.png',
            // shadowUrl: 'assets/images/marker-shadow.png',
          }),
        }).bindTooltip(this.order.user.name + ' ' + moment(data.fixed_time).format('DD/MM/YYYY HH:mm'),
          {permanent: false, direction: 'bottom', className: 'tooltipStyle'})
          .openTooltip().addTo(itemsLevel_1);
      } catch (e) {
        console.error(e);
      }

    }

    this.layerGroup = [itemsLevel_1];
    this.map.fitBounds(L.latLngBounds(markers.map(m => m.getLatLng())));

    try {
      if (this.order.user) {
        const index = this.order.order_status.findIndex(s => s.status_name === 'order_accept');
        if (index > -1) {
          const status = this.order.order_status[index];
          const status2 = this.order.order_status[this.order.order_status.length - 1];
          try {
            const res = await this.http.query({
              __user_id__equal: this.order.user.id,
              __fix_time__datetime_btw: moment(status.created_on).toJSON() + ',' + moment(status2.created_on).toJSON(),
            }, 'positions');
            if (res && res.data) {
              const coords = res.data.filter(d => d.latitude && d.longitude).map(d => [d.latitude, d.longitude]);

              const polyline = L.polyline(coords, {color: 'red'});
              itemsLevel_1.addLayer(polyline);
              if (coords.length) {
                // const start = L.marker(coords[0], {
                //     icon: icon({
                //         iconSize: [35, 35],
                //         iconAnchor: [13, 46],
                //         iconUrl: 'assets/images/Asset3.png',
                //         // shadowUrl: 'assets/images/marker-shadow.png',
                //     }),
                // });
                // itemsLevel_1.addLayer(start);
              }
            }
          } catch (e) {
            console.error(e);
          }
        }

      }
    } catch (e) {
      console.error(e);
    }
    this.cd.markForCheck();
  }

  getStatus(name: string, timeline: any[]): boolean {
    return timeline.find(status => status.status_name === name);
  }

  getStatusDelay(name: string, timeline: any[]) {
    let status = timeline.find(s => s.status_name === name);
    if (!status) {
      status = timeline[0];
    }
    const diff = moment.duration(moment().diff(status.created_on));
    return [diff.asHours().toFixed(0) + ':' + (diff.asMinutes() % 60).toFixed(0),
      diff.asMinutes() > this.delays[name].alert ? 'danger' : diff.asMinutes() > this.delays[name].warn ? 'warning' : null];
  }

  getStatusTime(name: string, timeline: any[]) {
    return timeline.find(status => status.status_name === name);
  }

  async editOrder(order: any) {
    this.orderService.sub.unsubscribe();
    const dialog = this.nbDialogService.open(RequestDialogComponent, {
      context: {
        orderData: Object.assign({}, order),
        step: 2,
        showMerchantForm: true,
      },
    });
    await dialog.onClose.toPromise();
    await this.orderService.init();
  }

  async cancelOrder(dialog: any, order: any, index: number) {
    try {
      const _dialog = this.nbDialogService.open(dialog);
      _dialog.onClose.subscribe(async (res) => {
        if (res) {
          await this.http.create({
              order_id: order.id,
              status_id: this.user.statuses.find(s => s.unique_id < 0).id,
            },
            {}, 'order_status');
          this.tabs[6].count += 1;
          this.orderList.splice(index, 1);
          const orderIndex = this.orders.findIndex(o => o.id === order.id);
          if (orderIndex > -1) {
            this.orders[orderIndex].status_name = 'order_cancel';
            this.orders[orderIndex].status_code = -100;
          }
          this.cd.detectChanges();
        }
      });
    } catch (e) {
      console.error(e);
    }

  }

  orderTabChange(event) {
    switch (event.nextId) {
      case 'UNACCEPTED': {
        this.activeTab = this.tabs[0];
        this.getOrders();
        break;
      }
      case 'ACCEPTED': {
        this.activeTab = this.tabs[1];
        this.getOrders();
        break;
      }
      case 'REACHED': {
        this.activeTab = this.tabs[2];
        this.getOrders();
        break;
      }
      case 'DISPATCHED': {
        this.activeTab = this.tabs[3];
        this.getOrders();
        break;
      }
      case 'REACHED CUSTOMER': {
        this.activeTab = this.tabs[4];
        this.getOrders();
        break;
      }
      case 'DELIVERED': {
        this.activeTab = this.tabs[5];
        this.getOrders();
        break;
      }
      case 'CANCELLED': {
        this.activeTab = this.tabs[6];
        this.getOrders();
        break;
      }
      default: {
        break;
      }
    }
  }

  setOrders(res) {
    if (res) {
      res.forEach(order => {
        const index = this.orders.findIndex(o => o.id === order.id);
        if (index > -1) {
          this.orders[index] = Object.assign({}, order);
        } else {
          this.orders.push(order);
        }
      });
      if (this.order) {
        const order = this.orders.find(o => o.id === this.order.id);
        if (order) {
          this.order = order;
        }
      }

      if (res.length) {
        this.getOrders();
      }
    }

  }

  getOrders() {

    // {name: 'NEW', statusCode: [0, 100], count: 0},
    // {name: 'ACCEPTED', statusCode: [101, 200], count: 0},
    // {name: 'DISPATCHED', statusCode: [201, 300], count: 0},
    // {name: 'DELIVERED', statusCode: [301, 400], count: 0},
    // {name: 'CANCELLED ORDERS', statusCode: [-1, -100], count: 0},

    delete this.orderList;
    this.orderList = [];
    this.tabs[0].count = 0;
    this.tabs[1].count = 0;
    this.tabs[2].count = 0;
    this.tabs[3].count = 0;
    this.tabs[4].count = 0;
    this.tabs[5].count = 0;
    this.tabs[6].count = 0;
    this.orders.forEach(order => {
      if (order.status_code < 0) {
        this.tabs[6].count += 1;
      } else {
        if (order.status_code > 350) {
          this.tabs[5].count += 1;
        } else {
          if (order.status_code <= 100) {
            this.tabs[0].count += 1;
          } else {
            if (order.status_code <= 200) {
              this.tabs[1].count += 1;
            } else {
              if (order.status_code <= 250) {
                this.tabs[2].count += 1;
              } else {
                if (order.status_code <= 300) {
                  this.tabs[3].count += 1;
                } else {
                  if (order.status_code <= 350) {
                    this.tabs[4].count += 1;
                  }
                }
              }
            }
          }
        }
      }

      if (this.activeTab.statusCode[0] > -1 && this.activeTab.statusCode[0] <= order.status_code &&
        order.status_code <= this.activeTab.statusCode[1]) {
        this.orderList.push(order);
      }
      if (this.activeTab.name === 'CANCELLED') {
        if (order.status_code < 0) {
          this.orderList.push(order);
        }
      }

    });
    if (this.activeTab.statusCode[0] <= 500) {
      this.orderList = this.orderList.sort((a, b) => {
        return moment(b.created_on).unix() - moment(a.created_on).unix();
      });
    } else {
      this.orderList = this.orderList.sort((a, b) => {
        return moment(b.created_on).unix() - moment(a.created_on).unix();
      });
    }
    this.cd.detectChanges();
  }

  createOrder() {
    this.nbDialogService.open(RequestDialogComponent, {context: {showMerchantForm: true}});
  }

  viewItems() {
    this.nbDialogService.open(ViewItemsComponent, {context: {markers: this.order.markers}});
  }

  @HostListener('window:beforeunload')
  async ngOnDestroy() {
    try {
      this.sub.unsubscribe();
    } catch (e) {
    }
    try {
      this.sub2.unsubscribe();
      this.orderService.sub.unsubscribe();
    } catch (e) {
    }
  }

  async removeRider(orderId: number) {
    try {
      await this.http.create({
        order_id: orderId,
        status_id: this.user.statuses.find(s => s.name === 'rider_remove').id,
      }, {}, 'order_status');
      this.orders.find(o => {
        if (o.id === orderId) {
          o.user_id = null;
          o.user = null;
        }
        return o.id === orderId;
      });
      this.orderList.find(o => {
        if (o.id === orderId) {
          o.user_id = null;
          o.user = null;
        }
        return o.id === orderId;
      });
    } catch (e) {
      console.error(e);
    }

  }

  async setRider(ev: any, orderId: number) {
    try {
      await this.http.update(orderId, {user_id: ev.id}, {}, 'order');
      this.orders.find(o => {
        if (o.id === orderId) {
          o.user_id = ev.id;
          o.user = {name: ev.name, id: ev.id, phone: ev.phone};
        }
        return o.id === orderId;
      });
      this.orderList.find(o => {
        if (o.id === orderId) {
          o.user_id = ev.id;
          o.user = <User>{name: ev.name, id: ev.id, phone: ev.phone};
        }
        return o.id === orderId;
      });
    } catch (e) {
      console.error(e);
    }


  }

  showRiderMarker(userId: number) {

  }

  async outletChange(outletId: number) {
    this.orderService.outletId = outletId;
    this.orders = [];
    this.orderList = [];
    this.cd.detectChanges();
    await this.orderService.init();
  }
}

