import {Injectable} from '@angular/core';
import {NbToastrService} from '@nebular/theme';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  config = {
    status: 'success',
    destroyByClick: true,
    duration: 5000,
    preventDuplicates: false,
  };

  constructor(private toastrService: NbToastrService) {
  }

  showToast(message: string, title: string, isError: boolean) {
    if (isError) {
      this.config.status = 'danger';
    } else {
      this.config.status = 'success';
    }
    // @ts-ignore
    this.toastrService.show(message, title, this.config);
  }
}
