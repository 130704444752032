import {NgModule} from '@angular/core';

import {UserManagementRoutingModule} from './user-management-routing.module';
import {UserComponent} from './user/user.component';
import {UserManagementComponent} from './user-management/user-management.component';
import {UserModalComponent} from './user/user-modal/user-modal.component';
import {NbDialogModule} from '@nebular/theme';
import {SharedModule} from '../../common/shared.module';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import {UserInfoDialogComponent} from './user/user-info-dialog/user-info-dialog.component';
import {OutletComponent} from './outlet/outlet.component';
import {AddOutletComponent} from './outlet/add-outlet/add-outlet.component';

@NgModule({
  declarations: [UserComponent, UserManagementComponent,
    UserModalComponent, UserInfoDialogComponent ,
     OutletComponent, AddOutletComponent],
  imports: [
    SharedModule,
    NbDialogModule.forChild(),
    UserManagementRoutingModule,
    LeafletModule.forRoot(),
  ],
  entryComponents: [ UserModalComponent, UserInfoDialogComponent, AddOutletComponent],

})
export class UserManagementModule {
}
