import {NgModule} from '@angular/core';

import {OrderManagementRoutingModule} from './order-management-routing.module';
import {OrdersComponent} from './orders/orders.component';
import {AgmCoreModule} from '@agm/core';
import {NbDialogModule} from '@nebular/theme';
import {MomentModule} from 'angular2-moment';
import {ViewItemsComponent} from './orders/view-items/view-items.component';
import {EditDialogComponent} from './orders/edit-dialog/edit-dialog.component';
import {OwlDateTimeModule, OwlNativeDateTimeModule} from 'ng-pick-datetime';
import {SharedModule} from '../../common/shared.module';

@NgModule({
  declarations: [OrdersComponent, ViewItemsComponent, EditDialogComponent],
  imports: [
    OrderManagementRoutingModule,
    AgmCoreModule,
    NbDialogModule.forChild(),
    MomentModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    SharedModule,
  ],
  entryComponents: [EditDialogComponent, ViewItemsComponent],
})
export class OrderManagementModule {
}
