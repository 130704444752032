import {Component, Input, OnInit} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';
import {DataService} from '../../../../services/data.service';
import {ToastService} from '../../../../services/toast.service';
import {Address, User} from '../../../../common/models/user';


@Component({
  selector: 'app-user-modal',
  templateUrl: './user-modal.component.html',
  styleUrls: ['./user-modal.component.scss'],
})
export class UserModalComponent implements OnInit {

  branches: any;
  categories: any;
  roles: any;
  title: string;
  address: Address = {} as Address;
  types: any[] = [];
  parents: any[] = [];
  zones: any [] = [];
  password: string = null;
  onBoardingDetails: any;
  vehicleDetails: any;

  @Input()
  data: User;
  @Input()
  users: any;

  constructor(protected ref: NbDialogRef<UserModalComponent>, private http: DataService, private toastService: ToastService) {

  }

  ngOnInit() {
    this.title = 'Add User';
    this.getCategories().then();
    // this.getBranches().then();
    this.getRoles().then();
    // this.getZones().then();
    if (!this.data) {
      this.data = {categories: []} as User;
    }
    if (this.data && this.data.address) {
      this.address = this.data.address;
    }

    if (this.data.id) {
      this.title = 'Edit User';
      this.categories = this.data.categories.map(t => t.id);
      this.getOnBoardingDetail().then();
      this.getVehicleDetail().then();
    }
  }

  cancel() {
    this.ref.close();
  }

  async getCategories() {
    try {
      const categories = await this.http.query({}, 'category');
      this.categories = categories.data;
    } catch (e) {
    }
  }

  async getRoles() {
    try {
      const roles = await this.http.query({}, 'role');
      this.roles = roles.data;
    } catch (e) {
    }
  }

  async getOnBoardingDetail() {
    try {
      const onBoardingDetails = await this.http.query({__user_id__equal: this.data.id}, 'on_boarding_detail');
      this.onBoardingDetails = onBoardingDetails.data[0];
    } catch (e) {
      this.onBoardingDetails = {};
    }
  }

  async getVehicleDetail() {
    try {
      const vehicleDetails = await this.http.query({__user_id__equal: this.data.id}, 'vehicle_detail');
      this.vehicleDetails = vehicleDetails.data[0];
    } catch (e) {
      this.vehicleDetails = {};
    }
  }


  async updateUserType(event, type: any) {
    if (event) {
      try {
        await this.http.create({
          __action: 'add',
          user_id: this.data.id,
          user_type_id: type.id,
        }, {}, 'user_type_mapping_association');
        this.data.categories.push(type);
        this.toastService.showToast('Data Saved successfully', 'Saved', false);
      } catch (e) {
        console.error(e);
        this.toastService.showToast('Something went wrong.Please try again.', 'Error', true);
      }

    } else {
      try {
        await this.http.create({
          __action: 'remove',
          user_id: this.data.id,
          user_type_id: type.id,
        }, {}, 'user_type_mapping_association');
        this.data.categories.splice(this.data.categories.findIndex(r => r.id === type.id), 1);
        this.toastService.showToast('Data Saved successfully', 'Saved', false);
      } catch (e) {
        console.error(e);
        this.toastService.showToast('Something went wrong.Please try again.', 'Error', true);
      }
    }
  }

  async submit() {
    if (this.password) {
      this.data.password = this.password;
    }

    this.data.address = this.address;

    try {
      if (!this.data.id) {
        const user = await this.http.create(this.data, {__include: '', __only: ['id']}, 'user');
        this.toastService.showToast('Data Saved successfully', 'Saved', false);
        this.data.id = user[0].id;
        this.getOnBoardingDetail().then();
        this.getVehicleDetail().then();
      } else {
        delete this.data.user_device;
        await this.http.update(this.data.id, this.data, {__include: '', __only: ['id']}, 'user');
        this.toastService.showToast('Data updated successfully.', 'Saved', false);
        this.ref.close();
      }
    } catch (e) {
      console.error(e);
      this.toastService.showToast('Something went wrong.Please try again.', 'Error', true);
    }
  }


  checkUserType(type: any) {
    if (this.data.categories) {
      return this.data.categories.findIndex(r => r.id === type.id) > -1;
    }
  }

  getParentById(branchId: string) {
    this.parents = this.users;
  }

  updateAddress(event) {
    if (event) {
      this.address.google_address = event.google_address;
      this.address.latitude = event.latitude;
      this.address.longitude = event.longitude;
    } else if (this.data.id && this.data.address) {
      this.address = this.data.address;
    }

  }

  async addBranch($event, condition: string, index?: number) {
    if (condition === 'add') {
      await this.http.create({
        __action: 'add',
        user_id: this.data.id,
        branch_id: $event.id
      }, {}, 'user_branch_association');
      this.data.branch.push($event);
    } else {
      await this.http.create({
        __action: 'remove',
        user_id: this.data.id,
        branch_id: $event.id
      }, {}, 'user_branch_association');
      this.data.branch.splice(index, 1);
    }
  }

  async addOutlet($event, condition: string, index?: number) {
    if (condition === 'add') {
      await this.http.create({
        __action: 'add',
        user_id: this.data.id,
        outlet_id: $event.id
      }, {}, 'user_outlet_association');
      this.data.outlets.push($event);
    } else {
      await this.http.create({
        __action: 'remove',
        user_id: this.data.id,
        outlet_id: $event.id
      }, {}, 'user_outlet_association');
      this.data.outlets.splice(index, 1);
    }
  }
}
