import {ElementRef, Injectable, ViewChild} from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireMessaging } from '@angular/fire/messaging';

import { BehaviorSubject } from 'rxjs';
import {DataService} from './data.service';
@Injectable()
export class MessagingService {

  currentMessage = new BehaviorSubject(null);
  // audio = new Audio('assets/order_delivered.mp3');
  public audio: HTMLAudioElement;


  constructor(
    private angularFireDB: AngularFireDatabase,
    private angularFireMessaging: AngularFireMessaging,
    private http: DataService) {

    this.receiveMessage();
    this.audio = new Audio();
    this.audio.src = 'assets/order_delivered.mp3';
    this.angularFireMessaging.messaging.subscribe(
      (messaging) => {
        console.log(messaging);
        messaging.onMessage = messaging.onMessage.bind(messaging);
        messaging.onTokenRefresh = messaging.onTokenRefresh.bind(messaging);
      }
    );
  }

  /**
   * update token in firebase database
   *
   * @param userId userId as a key
   * @param token token as a value
   */
  updateToken(userId, token) {
    // we can change this function to request our backend service
    this.http.update(userId, {web_token: token}, {__include: '', __only: ['id']}, 'user').then();
  }

  /**
   * request permission for notification from firebase cloud messaging
   *
   * @param userId userId
   */
  requestPermission(userId) {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        console.log(token);
        this.updateToken(userId, token);
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  /**
   * hook method when new notification received in foreground
   */
  receiveMessage() {
    const self = this;
    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        console.log('new message received. ', payload);
        // this.audio.load();
        self.audio.play();
        this.currentMessage.next(payload);
      });
  }
}
