import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../services/auth-guard.service';
import {PageComponent} from './page/page.component';
import {OrderReportComponent} from './order-report/order-report.component';


const routes: Routes = [

  {
    path: '', component: PageComponent, children: [
      {
        path: 'map', loadChildren: './map/map.module#MapModule',
        canActivate: [AuthGuard],
      },

      {
        path: 'orders', loadChildren: './order-management/order-management.module#OrderManagementModule',
        canActivate: [AuthGuard],
      },
      {
        path: 'users', loadChildren: './user-management/user-management.module#UserManagementModule',
        canActivate: [AuthGuard],
      },
      {
        path: 'order-report', component: OrderReportComponent,
        canActivate: [AuthGuard],
      },
    ]
  },
  {path: '', redirectTo: 'map', pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule {
}
