import {Component, OnInit} from '@angular/core';
import {DataService} from '../../../services/data.service';
import {UserModalComponent} from './user-modal/user-modal.component';
import {NbDialogService} from '@nebular/theme';
import {UserInfoDialogComponent} from './user-info-dialog/user-info-dialog.component';
import {User} from '../../../common/models/user';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  entryComponents: [UserModalComponent],
})
export class UserComponent implements OnInit {
  searchText: any;
  users: any[] = [];
  columns: any[] = [];
  dialogInputColumn: any[] = [];

  constructor(private http: DataService, private nbDialogService: NbDialogService) {
    this.columns =
      [{
        name: 'name',
        displayName: 'Users',
      },
        {
          name: 'phone',
          displayName: 'Mobile No.',
        },
        {
          name: 'email',
          displayName: 'Email',
        },
      ];

    this.dialogInputColumn =
      [{
        name: 'name',
        type: 'text',
      },
        {
          name: 'phone',
          type: 'text',
        },
        {
          name: 'email',
          type: 'text',
        },
        {
          name: 'app_ver.',
          type: 'text',
        },
        {
          name: 'address',
          type: 'text',
        },
      ];
  }

  ngOnInit() {
    this.getUsers().then();
  }
  async getUsers() {
    try {
      const users = await this.http.query({__include: ['user_device', 'branch']}, 'user');
      this.users = users.data;
    } catch (e) {
    }
  }

  async add(user?: User) {
    this.nbDialogService.open(UserModalComponent, {
      closeOnBackdropClick: false, context: {
        data: user ? Object.assign({}, user) : undefined,
      },
    })
      .onClose.subscribe(name => name);
  }

  async viewInfo($event) {
    this.nbDialogService.open(UserInfoDialogComponent, {context: {customData: $event}});
  }

}
