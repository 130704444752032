import {Component, Input, OnInit} from '@angular/core';

import {NbMenuService, NbSidebarService} from '@nebular/theme';
import {NgbDropdownMenu} from '@ng-bootstrap/ng-bootstrap';
import {UserService} from '../../services/user.service';
import {DataService} from '../../services/data.service';

@Component({
  selector: 'app-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
  viewProviders: [NgbDropdownMenu, NbMenuService]
})
export class HeaderComponent implements OnInit {

  @Input() position = 'normal';

  user: any;
  page = 1;
  events: any[] = [];
  loading = false;
  userMenu = [
    {title: 'Account'},
    {title: 'Logout'},
  ];
  placeholders: any[] = [];
  pageSize = 50;

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              public userService: UserService,
              private http: DataService,) {
  }

  ngOnInit() {
    this.menuService.onItemClick()
      .subscribe(event => {
        if (event.item.title.toString().toLowerCase().trim() === 'logout') {
          this.userService.logOut();
        }
      });
    this.loadNext();
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');

    return false;
  }

  goToHome() {
    this.menuService.navigateHome();
  }

  updateUrl($event) {
    $event.target.src = 'https://admin.jeeblynow.com/assets/images/1.svg';
  }

  async loadNext() {
    if (this.loading) {
      return;
    }
    this.loading = true;
    try {
      this.placeholders = new Array<number>(this.pageSize);
      const events = await this.http.query({
        __order_by: '-created_on',
        __limit: this.pageSize
      }, 'event');
      this.loading = false;
      this.events = events.data;
      this.placeholders = [];
    } catch (e) {

    }
  }
}
