import {Component, OnInit} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';

@Component({
  selector: 'app-view-items',
  templateUrl: './view-items.component.html',
  styleUrls: ['./view-items.component.scss'],
})
export class ViewItemsComponent implements OnInit {
  baseUrl: any = 'https://roadcast.sgp1.digitaloceanspaces.com/upload/';
  markers: any[] = [];

  constructor(protected ref: NbDialogRef<ViewItemsComponent>) {

  }

  ngOnInit() {

  }

  cancel() {
    this.ref.close();
  }
}
